import { REACT_ENV } from "environmentVariables";
import React, { useEffect } from "react";

function Login (): React.ReactElement {

    useEffect(() =>
    {
        window.location.replace(`${REACT_ENV.REACT_APP_UI_URL_3EDGES}/login?redirectURL=${window.location.origin}`);
    }, []);

    return null;
}

export default Login;
